import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import compare from 'trivial-compare';

import { APIClient, APIUtils } from '@agerpoint/api';
import { uuidRegex } from '@agerpoint/utilities';

import { useCapturesViewerContext } from '../../../captures-viewer';

export const useThreeDAnnotationsPluginQueries = () => {
  const { captureId, capturePublicUuid } = useParams();

  const { selectedCaptureJob } = useCapturesViewerContext();

  const queryClient = useQueryClient();

  const captureJobId = useMemo(
    () => selectedCaptureJob?.id,
    [selectedCaptureJob]
  );

  const captureJobUuid = useMemo(
    () => selectedCaptureJob?.uuid,
    [selectedCaptureJob]
  );

  const captureObjectsBySelectedCaptureJobQuery =
    APIClient.useGetCaptureObjectsByCaptureId(Number(captureId), {
      query: {
        queryKey: [
          APIUtils.QueryKey.captures,
          { captureId: Number(captureId) },
          APIUtils.QueryKey.captureJobs,
          { captureJobId: Number(captureJobId) },
          APIUtils.QueryKey.captureObjects,
        ],
        enabled:
          Number.isSafeInteger(Number(captureId)) &&
          Number.isSafeInteger(Number(captureJobId ?? undefined)),
        select: (data) =>
          data
            .filter((co) => co.captureJobId === Number(captureJobId))
            .sort((a, b) => compare(a.id, b.id)),
      },
    });

  const sharedCaptureObjectsBySelectedCaptureJobQuery =
    APIClient.useGetCaptureObjectsBySharedCaptureJob(captureJobUuid as string, {
      query: {
        queryKey: [
          APIUtils.QueryKey.sharedCaptures,
          { capturePublicUuid: capturePublicUuid },
          APIUtils.QueryKey.captureJobs,
          { captureJobUuid: captureJobUuid },
          APIUtils.QueryKey.captureObjects,
        ],
        enabled:
          !!capturePublicUuid &&
          uuidRegex.test(capturePublicUuid) &&
          !!captureJobUuid &&
          uuidRegex.test(captureJobUuid),
        select: (data) => data.sort((a, b) => compare(a.id, b.id)),
      },
    });

  const createCaptureObject = APIClient.usePostCaptureObject({
    mutation: {
      onSettled: async () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.captureJobs,
            { captureJobId: Number(captureJobId) },
            APIUtils.QueryKey.captureObjects,
          ],
        });
      },
    },
  });

  const updateCaptureObject = APIClient.usePutCaptureObjectById({
    mutation: {
      onSettled: async () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.captureJobs,
            { captureJobId: Number(captureJobId) },
            APIUtils.QueryKey.captureObjects,
          ],
        });
      },
    },
  });

  const deleteCaptureObject = APIClient.useDeleteCaptureObject({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [
            APIUtils.QueryKey.captures,
            { captureId: Number(captureId) },
            APIUtils.QueryKey.captureJobs,
            { captureJobId: Number(captureJobId) },
            APIUtils.QueryKey.captureObjects,
          ],
        });
      },
    },
  });

  const deleteCustomCaptureObjectAttributes =
    APIClient.useDeleteCaptureObjectCustomAttribute({
      mutation: {
        onSettled: async () => {
          queryClient.invalidateQueries({
            queryKey: [
              APIUtils.QueryKey.captures,
              { captureId: Number(captureId) },
              APIUtils.QueryKey.captureJobs,
              { captureJobId: Number(captureJobId) },
              APIUtils.QueryKey.captureObjects,
            ],
          });
        },
      },
    });

  const updateCustomCaptureObjectAttributes =
    APIClient.usePutCaptureObjectCustomAttributeById({
      mutation: {
        onSettled: async () => {
          queryClient.invalidateQueries({
            queryKey: [
              APIUtils.QueryKey.captures,
              { captureId: Number(captureId) },
              APIUtils.QueryKey.captureJobs,
              { captureJobId: Number(captureJobId) },
              APIUtils.QueryKey.captureObjects,
            ],
          });
        },
      },
    });

  return {
    createCaptureObject,
    updateCaptureObject,
    deleteCaptureObject,
    deleteCustomCaptureObjectAttributes,
    captureObjectsBySelectedCaptureJobQuery,
    sharedCaptureObjectsBySelectedCaptureJobQuery,
    updateCustomCaptureObjectAttributes,
  };
};
