import { Group, PerspectiveCamera, Scene, Vector3 } from 'three';

import {
  Annotation3dLines,
  Annotation3dPoints,
  Annotation3dPolygons,
  CaptureObjectGenericAttributes,
  ColorsThreeD,
  GeomType,
  HexColor,
  IAnnotations3d,
} from '@agerpoint/types';
import {
  AnnotationGroupName,
  getNextAnnotationColor,
} from '@agerpoint/utilities';

import { AnnotationBase } from '../annotations.base';
import { getCoordinates } from '../utility/annotations-utility';
import { AnnotationsDispatcher } from './annotations-dispatcher';
import { Line3d } from './line-3d';
import { MultiPoint3d } from './multi-point-3d';
import { Point3d } from './point-3d';

/**
 * Class representing 3D annotations.
 * @extends AnnotationBase
 * @implements IAnnotations3d
 */
export class Annotations3d extends AnnotationBase implements IAnnotations3d {
  private annotationGroup = new Group();
  public annoMgr: AnnotationsDispatcher;
  private container: HTMLElement;

  /**
   * Create an instance of AnnotationBase.
   * @param {Scene} scene - The Three.js scene.
   * @param {PerspectiveCamera} perspectiveCamera - The camera used in the scene.
   * @param {boolean} isPotree - Whether Potree is being used.
   */
  constructor(
    scene: Scene,
    perspectiveCamera: PerspectiveCamera,
    isPotree: boolean,
    isReadOnly: boolean,
    container: HTMLElement
  ) {
    super(scene, perspectiveCamera, isPotree, isReadOnly);
    const annoGroup = scene.getObjectByName(AnnotationGroupName);
    if (!annoGroup) {
      this.annotationGroup = new Group();
      this.annotationGroup.name = AnnotationGroupName;
      this.scene.add(this.annotationGroup);
    }
    this.annoMgr = new AnnotationsDispatcher();
    this.isPotree = isPotree;
    this.container = container;
  }

  destroy() {
    this.remove3dLines();
    super.destroy();
  }

  /**
   * Add a 3D point annotation.
   * @param {CaptureObjectGenericAttributes} coAttrs - The generic attributes of the point.
   * @param {Vector3} [position=new Vector3(0, 0, 0)] - The position of the point.
   * @param {Annotation3dPoints} type - The type of the point.
   * @param {boolean} [visible] - Whether the point is visible.
   * @returns {Point3d} The created point.
   */
  public add3dPoint(
    coAttrs: CaptureObjectGenericAttributes,
    position: Vector3 = new Vector3(0, 0, 0),
    type: Annotation3dPoints,
    visible?: boolean
  ) {
    const point = this.createPoint3d(coAttrs, position, type, false, visible);
    if (type === Annotation3dPoints.AnnotationPoint) {
      this.notifyListeners();
    }
    return point;
  }

  /**
   * Add a 3D multi-point annotation.
   * @param {CaptureObjectGenericAttributes} coAttrs - The generic attributes of the multi-point.
   * @param {Vector3[]} positions - The positions of the points.
   * @param {Annotation3dPoints} type - The type of the points.
   * @returns {MultiPoint3d} The created multi-point.
   */
  public add3dMultiPoint(
    coAttrs: CaptureObjectGenericAttributes,
    positions: Vector3[],
    type: Annotation3dPoints
  ) {
    const multiPoint = this.createMultiPoint3d(coAttrs, positions, type);
    this.notifyListeners();
    return multiPoint;
  }

  /**
   * Add a 3D line annotation.
   * @param {CaptureObjectGenericAttributes} coAttrs - The generic attributes of the line.
   * @param {Vector3[]} points - The points defining the line.
   * @param {Annotation3dLines} type - The type of the line.
   * @returns {Line3d} The created line.
   */
  public add3dLine(
    coAttrs: CaptureObjectGenericAttributes,
    points: Vector3[],
    type: Annotation3dLines
  ) {
    const line = this.createLine3d(coAttrs, points, type);
    this.notifyListeners();
    return line;
  }

  /**
   * Add a 3D sprite annotation.
   * @param {CaptureObjectGenericAttributes} coAttrs - The generic attributes of the sprite.
   * @param {Vector3} [position=new Vector3(0, 0, 0)] - The position of the sprite.
   * @param {Annotation3dPoints} type - The type of the sprite.
   * @returns {Point3d} The created sprite.
   */
  public add3dSprite(
    coAttrs: CaptureObjectGenericAttributes,
    position: Vector3 = new Vector3(0, 0, 0),
    type: Annotation3dPoints
  ) {
    const sprite = this.createPoint3d(coAttrs, position, type, true);
    this.notifyListeners();
    return sprite;
  }

  /**
   * Add a 3D polygon annotation.
   * @param {CaptureObjectGenericAttributes} coAttrs - The generic attributes of the polygon.
   * @param {Vector3[]} points - The points defining the polygon.
   * @param {Annotation3dPolygons} type - The type of the polygon.
   * @returns {Line3d} The created polygon.
   */
  public add3dPolygon(
    coAttrs: CaptureObjectGenericAttributes,
    points: Vector3[],
    type: Annotation3dPolygons
  ) {
    const polygon = this.createPolygon3d(coAttrs, points, type);
    this.notifyListeners();
    return polygon;
  }

  /**
   * Remove a 3D sprite by ID.
   * @param {string} id - The ID of the sprite.
   */
  public remove3dSpriteById(id: string) {
    this.remove3dPointById(id);
    this.notifyListeners();
  }

  /**
   * Remove 3D points by type.
   * @param {Annotation3dPoints} type - The type of the points.
   */
  public remove3dPointsByType(type: Annotation3dPoints) {
    AnnotationBase.annoStore.removePoint3dByType(type);
    this.notifyListeners();
  }

  /**
   * Remove a 3D line by ID.
   * @param {string} id - The ID of the line.
   */
  public remove3dLineById(id: string) {
    AnnotationBase.annoStore.removeLine3dById(id);
    this.notifyListeners();
  }

  /**
   * Remove all 3D lines
   */
  public remove3dLines() {
    AnnotationBase.annoStore.removeAllLines3d();
    this.notifyListeners();
  }

  /**
   * Remove a 3D polygon by ID.
   * @param {string} id - The ID of the polygon.
   */
  public remove3dPolygonById(id: string) {
    AnnotationBase.annoStore.removePolygon3dById(id);
    this.notifyListeners();
  }

  /**
   * Remove a 3D multi-point by ID.
   * @param {string} id - The ID of the multi-point.
   */
  public remove3dMultiPointById(id: string) {
    AnnotationBase.annoStore.removeMultiPoint3dById(id);
    this.notifyListeners();
  }

  /**
   * Get a 3D point by ID.
   * @param {string} id - The ID of the point.
   * @returns {Point3d} The point.
   */
  public get3dPointById(id: string) {
    return AnnotationBase.annoStore.getPoint3dById(id);
  }

  /**
   * Get a 3D multi-point by ID.
   * @param {string} id - The ID of the multi-point.
   * @returns {MultiPoint3d} The multi-point.
   */
  public get3dMultiPointById(id: string) {
    return AnnotationBase.annoStore.getMultiPoint3dById(id);
  }

  /**
   * Get a 3D line by ID.
   * @param {string} id - The ID of the line.
   * @returns {Line3d} The line.
   */
  public get3dLineById(id: string) {
    return AnnotationBase.annoStore.getLine3dById(id);
  }

  /**
   * Get the position of a 3D line by ID.
   * @param {string} id - The ID of the line.
   * @returns {Vector3[]} The positions of the line.
   */
  public get3dLinePositionById(id: string) {
    return AnnotationBase.annoStore.getLinePositionById(id);
  }

  public getNext3dMultiPointColor(): HexColor {
    const lastPnt = AnnotationBase.annoStore.getLast3dMultiPoint();
    const nextColor = getNextAnnotationColor(lastPnt?.color);
    return nextColor;
  }

  public getNext3dLineColor(): HexColor {
    const lastLine = AnnotationBase.annoStore.getLast3dLine();
    return getNextAnnotationColor(lastLine?.color);
  }

  /**
   * Highlight a 3D point by ID.
   * @param {string} id - The ID of the point.
   * @param {ColorsThreeD|HexColor} [color] - The color to highlight the point.
   */
  public highlight3dPointById(id: string, color?: ColorsThreeD | HexColor) {
    // this.unHighlightAll();
    AnnotationBase.annoStore.highlightPoint3dById(id, color);
    this.setSelectedObject(id, Annotation3dPoints.AnnotationPoint);
    this.notifyListeners();
  }

  /**
   * Highlight a 3D line by ID.
   * @param {string} id - The ID of the line.
   */
  public highlight3dLineById(id: string) {
    // this.unHighlightAll();
    AnnotationBase.annoStore.highlightLine3dById(id);
    this.setSelectedObject(id, Annotation3dLines.AnnotationLine);
    this.notifyListeners();
  }

  /**
   * Highlight a 3D polygon by ID.
   * @param {string} id - The ID of the polygon.
   */
  public highlight3dPolygonById(id: string) {
    this.unHighlightAll();
    AnnotationBase.annoStore.highlightPolygon3dById(id);
    this.setSelectedObject(id, Annotation3dPolygons.AnnotationPolygon);
    this.notifyListeners();
  }

  /**
   * Highlight a 3D multi-point by ID.
   * @param {string} id - The ID of the multi-point.
   */
  public highlight3dMultiPointById(id: string) {
    this.unHighlightAll();
    AnnotationBase.annoStore.highlightMultiPoint3dById(id);
    this.setSelectedObject(id, Annotation3dPoints.MultiPointMarker);
    this.notifyListeners();
  }

  /**
   * Hide 3D points by type.
   * @param {Annotation3dPoints} type - The type of the points.
   */
  public hide3dPointsByType(type: Annotation3dPoints) {
    AnnotationBase.annoStore.hidePointsByType(type);
  }

  /**
   * Show 3D points by type.
   * @param {Annotation3dPoints} type - The type of the points.
   */
  public show3dPointsByType(type: Annotation3dPoints) {
    AnnotationBase.annoStore.showPoints3dByType(type);
  }

  /**
   * Hid all 3D annotations.
   */
  public hideAll3dAnnotations() {
    AnnotationBase.annoStore.hideAllGeometry();
  }

  /**
   * Show all 3D annotations.
   */
  public showAll3dAnnotations() {
    AnnotationBase.annoStore.showAllGeometry();
  }

  /**
   * Get a new ID for a 3D point.
   * @returns {string} The new ID.
   */
  public getNew3dPointId() {
    return AnnotationBase.annoStore.getNewPoint3dId();
  }

  /**
   * Get a new ID for a 3D multi-point.
   * @returns {string} The new ID.
   */
  public getNew3dMultiPointId() {
    return AnnotationBase.annoStore.getNewMultiPoint3dId();
  }

  /**
   * Get a new ID for a 3D line.
   * @returns {string} The new ID.
   */
  public getNew3dLineId() {
    return AnnotationBase.annoStore.getNewLine3dId();
  }

  /**
   * Get a new ID for a 3D polygon.
   * @returns {string} The new ID.
   */
  public getNew3dPolygonId() {
    return AnnotationBase.annoStore.getNewPolygon3dId();
  }

  /**
   * Update the look-at direction for all 3D points.
   */
  public updateAll3dPointLookAt() {
    AnnotationBase.annoStore.updateAllPoint3dLookAt();
  }

  /**
   * Update the position of a 3D multi-point by ID.
   * @param {string} id - The ID of the multi-point.
   * @param {Vector3[]} points - The new positions of the multi-point.
   */
  public update3dMultiPointPosition(id: string, points: Vector3[]) {
    AnnotationBase.annoStore.updateMultiPoint3dPosition(id, points);
  }

  /**
   * Update the position of a 3D line by ID.
   * @param {string} id - The ID of the line.
   * @param {Vector3[]} points - The new positions of the line.
   */
  public update3dLinePosition(id: string, points: Vector3[]) {
    AnnotationBase.annoStore.updateLine3dPosition(id, points);
  }

  /**
   * Update the position of a 3D polygon by ID.
   * @param {string} id - The ID of the polygon.
   * @param {Vector3[]} points - The new positions of the polygon.
   */
  public update3dPolygonPosition(id: string, points: Vector3[]) {
    AnnotationBase.annoStore.updatePolygon3dPosition(id, points);
  }

  /**
   * Finish creating a 3D multi-point.
   * @param {string} id - The ID of the multi-point.
   */
  public finishCreating3dMultiPoint(id: string) {
    AnnotationBase.annoStore.finishCreating3dMultiPoint(id);
  }

  /**
   * Finish creating a 3D line.
   * @param {string} id - The ID of the line.
   */
  public finishCreating3dLine(id: string) {
    AnnotationBase.annoStore.finishCreating3dLine(id);
  }

  /**
   * Finish creating a 3D polygon.
   * @param {string} id - The ID of the polygon.
   */
  public finishCreating3dPolygon(id: string) {
    AnnotationBase.annoStore.finishCreating3dPolygon(id);
  }

  /**
   * Clear all 3D points.
   */
  public clear3dPoints() {
    AnnotationBase.annoStore.removeAllPoints3d();
    this.notifyListeners();
  }

  /**
   * Clear all 3D points by type.
   */
  public clear3dPointsByType(type: Annotation3dPoints) {
    AnnotationBase.annoStore.removePoint3dByType(type);
    this.notifyListeners();
  }

  /**
   * Clear all 3D multi-points.
   */
  public clear3dMultiPoints() {
    AnnotationBase.annoStore.removeAllMultiPoints3d();
    this.notifyListeners();
  }

  /**
   * Clear all 3D multi-points by type.
   */
  public clear3dMultiPointsByType(type: Annotation3dPoints) {
    AnnotationBase.annoStore.removeMultiPoint3dByType(type);
    this.notifyListeners();
  }

  /**
   * Clear all 3D lines.
   */
  public clear3dLines() {
    AnnotationBase.annoStore.removeAllLines3d();
    this.notifyListeners();
  }

  /**
   * Clear all 3d Lines by type.
   */
  public clear3dLinesByType(type: Annotation3dLines) {
    AnnotationBase.annoStore.removeLine3dByType(type);
    this.notifyListeners();
  }

  /**
   * Clear all 3D polygons.
   */
  public clear3dPolygons() {
    AnnotationBase.annoStore.removeAllPolygons3d();
    this.notifyListeners();
  }

  /**
   * Clear all 3D polygons by type.
   */
  public clear3dPolygonsByType(type: Annotation3dPolygons) {
    AnnotationBase.annoStore.removePolygon3dByType(type);
    this.notifyListeners;
  }

  /**
   * Save a point annotation.
   * @param {string} id - The ID of the point.
   */
  public savePoint(id: string) {
    const obj = AnnotationBase.annoStore.getPoint3dById(id);
    obj.updateType(Annotation3dPoints.AnnotationPoint);
    this.annoMgr.createCapObj(obj, GeomType.Point);
    this.notifyListeners();
  }

  /**
   * Save a multi-point annotation.
   * @param {string} id - The ID of the multi-point.
   */
  public saveMultiPoint(id: string) {
    const obj = AnnotationBase.annoStore.getMultiPoint3dById(id);
    obj.updateType(Annotation3dPoints.MultiPointMarker);
    this.annoMgr.createCapObj(obj, GeomType.MultiPoint);
    AnnotationBase.annoStore.removeMultiPoint3dById(id);
    this.notifyListeners();
  }

  /**
   * Save a line annotation.
   * @param {string} id - The ID of the line.
   */
  public saveLine(id: string) {
    const obj = AnnotationBase.annoStore.getLine3dById(id);
    obj.updateType(Annotation3dLines.AnnotationLine);
    this.annoMgr.createCapObj(obj, GeomType.LineString);
    AnnotationBase.annoStore.removeLine3dById(id);
    // this.notifyListeners();
  }

  /**
   * Save update to existing line.
   * @param {string} id - The ID of the line.
   */
  public saveUpdatedLinePosition(id: string) {
    const obj = AnnotationBase.annoStore.getLine3dById(id);
    const coordinates = getCoordinates(GeomType.LineString, obj);
    if (!coordinates) return;
    this.annoMgr.updateCapObjPosition(id, coordinates);
    this.notifyListeners();
  }

  /**
   * Save a polygon annotation.
   * @param {string} id - The ID of the polygon.
   */
  public savePolygon(id: string) {
    const obj = AnnotationBase.annoStore.getPolygon3dById(id);
    obj.updateType(Annotation3dPolygons.AnnotationPolygon);
    this.annoMgr.createCapObj(obj, GeomType.Polygon);

    this.notifyListeners();
  }

  /**
   * Destroy all geometries.
   */
  public destroyGeometry() {
    this.clear3dPoints();
    this.clear3dLines();
    this.clear3dPolygons();
    this.clear3dMultiPoints();
  }

  /**
   * Remove a 3D point by ID.
   * @param {string} id - The ID of the point.
   */
  public remove3dPointById(id: string) {
    const pnt = AnnotationBase.annoStore.getPoint3dById(id);
    if (pnt) {
      AnnotationBase.annoStore.removePoint3dById(id);
      this.notifyListeners();
    }
  }

  render() {
    AnnotationBase.annoStore.render3dLines();
  }

  /**
   * Create a 3D point.
   * @private
   * @param {CaptureObjectGenericAttributes} coAttrs - The generic attributes of the point.
   * @param {Vector3} position - The position of the point.
   * @param {Annotation3dPoints} type - The type of the point.
   * @param {boolean} isSprite - Whether the point is a sprite.
   * @param {boolean} [visible] - Whether the point is visible.
   * @returns {Point3d} The created point.
   */
  private createPoint3d(
    coAttrs: CaptureObjectGenericAttributes,
    position: Vector3,
    type: Annotation3dPoints,
    isSprite: boolean,
    visible?: boolean
  ) {
    const existingPnt = AnnotationBase.annoStore.getPoint3dById(coAttrs.id);
    if (existingPnt) {
      AnnotationBase.annoStore.removePoint3dById(coAttrs.id);
    }
    const pnt = new Point3d(
      this.scene,
      this.perspectiveCamera,
      coAttrs.id,
      position,
      type,
      isSprite,
      coAttrs?.color,
      visible,
      this.isPotree,
      coAttrs?.name || ''
    );
    AnnotationBase.annoStore.addPoint3d(pnt);
    return pnt;
  }

  /**
   * Create a 3D multi-point.
   * @private
   * @param {CaptureObjectGenericAttributes} coAttrs - The generic attributes of the multi-point.
   * @param {Vector3[]} positions - The positions of the points.
   * @param {Annotation3dPoints} type - The type of the points.
   * @returns {MultiPoint3d} The created multi-point.
   */
  private createMultiPoint3d(
    coAttrs: CaptureObjectGenericAttributes,
    positions: Vector3[],
    type: Annotation3dPoints
  ) {
    const id = coAttrs?.id;
    if (!id) return;
    const existingMultiPnt = AnnotationBase.annoStore.getMultiPoint3dById(id);
    if (existingMultiPnt) {
      AnnotationBase.annoStore.removeMultiPoint3dById(id);
    }
    const multiPoint = new MultiPoint3d(
      this.scene,
      this.perspectiveCamera,
      id,
      type,
      coAttrs?.color,
      true,
      coAttrs?.name || ''
    );
    AnnotationBase.annoStore.addMultiPoint3d(multiPoint, id);
    return multiPoint;
  }

  /**
   * Create a 3D line.
   * @private
   * @param {CaptureObjectGenericAttributes} coAttrs - The generic attributes of the line.
   * @param {Vector3[]} points - The points defining the line.
   * @param {Annotation3dLines} type - The type of the line.
   * @returns {Line3d} The created line.
   */
  private createLine3d(
    coAttrs: CaptureObjectGenericAttributes,
    points: Vector3[],
    type: Annotation3dLines
  ) {
    const existingLine = AnnotationBase.annoStore.getLine3dById(coAttrs.id);
    if (existingLine) {
      AnnotationBase.annoStore.removeLine3dById(coAttrs.id);
    }
    const line = new Line3d(
      this.scene,
      this.perspectiveCamera,
      this.isPotree,
      this.isReadOnly,
      points,
      this.container,
      coAttrs
    );
    line.on('label-click', (id: string) => {
      this.emit('label-click', id, Annotation3dLines.AnnotationLine);
    });
    AnnotationBase.annoStore.addLine3d(line);
    return line;
  }

  /**
   * Create a 3D polygon.
   * @private
   * @param {CaptureObjectGenericAttributes} coAttrs - The generic attributes of the polygon.
   * @param {Vector3[]} points - The points defining the polygon.
   * @param {Annotation3dPolygons} type - The type of the polygon.
   * @returns {Line3d} The created polygon.
   */
  private createPolygon3d(
    coAttrs: CaptureObjectGenericAttributes,
    points: Vector3[],
    type: Annotation3dPolygons
  ) {
    const existingPolygon = AnnotationBase.annoStore.getPolygon3dById(
      coAttrs.id
    );
    if (existingPolygon) {
      AnnotationBase.annoStore.removePolygon3dById(coAttrs.id);
    }
    const line = new Line3d(
      this.scene,
      this.perspectiveCamera,
      this.isPotree,
      this.isReadOnly,
      points,
      this.container,
      coAttrs
    );
    AnnotationBase.annoStore.addPolygon3d(line);
    return line;
  }
}
