import { Vector2, Vector3 } from 'three';

import { APIClient, APIModels } from '@agerpoint/api';

import { IAnnotations3d } from './annotations';
import { CaptureObjectMarkerType } from './captures';
import { EventBusNames } from './event-bus';
import { IGsThreeDViewer } from './gs-three-d';
import {
  BackgroundOptionsValues,
  CameraState,
  MaterialType,
  MeasurementOptionsValues,
} from './potree-types';
import { IViewer, MarkerObjOptions } from './viewer';

export enum ViewerType {
  Potree = 'potree',
  Three = 'three',
  Video = 'video',
  Audio = 'audio',
  Image = 'image',
  Unavailable = 'unavailable',
}
export interface PotreeViewer2Props {
  cloudApp?: boolean;
  controller?: (controller: IPotreeViewerController) => void;
  showTools?: boolean;
  showCloudTools?: boolean;
  showShareTools?: boolean;
  plugins?: React.ReactElement[];
  token?: string;
}

export interface PotreeViewer2ControllerInfoClassificationProps {
  label: number;
  labelString: string;
  color: number[];
  visible: boolean;
}

export interface PotreeViewer2InfoProps {
  viewerReady: boolean;
  viewerInitialized: boolean;
  captureJobMetadata?: APIModels.CaptureJob | undefined | null;
  measurementsVisible: boolean;
  pointStyle: MaterialType;
  cameraPositionsVisible: boolean;
  classificationClasses?: PotreeViewer2ControllerInfoClassificationProps[];
  error: Error | undefined;
  sceneLoaded: boolean;
  cropBoxEditingActive: boolean;
}

export interface PotreeViewer2InfoModalProps {
  element: React.ReactElement;
  loc: {
    x: number;
    y: number;
    z: number;
  };
}

export interface PotreeViewer2AddObjectProps {
  captureObject: APIModels.CaptureObject;
  markerVisible?: boolean;
  labelVisible?: boolean;
  type?: CaptureObjectMarkerType;
  editable?: boolean;
  clickable?: boolean;
  includeApfGeometry?: boolean;
  captureMetadata?: APIModels.Capture;
}

export interface PotreeViewer2ZoomToLocationProps {
  loc: {
    x: number;
    y: number;
    z: number;
  };
  distance?: number;
}

export interface IPotreeViewerController {
  info: PotreeViewer2InfoProps;
  loadPointcloud: (eptId: number) => Promise<void>;
  loadPointcloudFromAzure: (url: string) => Promise<void>;
  setCaptureJobMetadata: (captureJob?: APIModels.CaptureJob) => void;
  removePointcloud: () => void;
  setPointStyle: (style: MaterialType) => void;
  loadCameraPositions: (
    images: APIModels.CaptureImage[],
    addPrisms?: boolean
  ) => void;
  setCameraPositionsVisible: (visible: boolean) => void;
  removeCameraPositions: () => void;
  setSelectedTool: (tool: MeasurementOptionsValues) => void;
  setMeasurementsVisible: (visible: boolean) => void;
  removeMeasurements: () => void;
  resetView: () => void;
  takeScreenshot: () => void;
  setCameraSettings: () => void;
  setBackground: (background: BackgroundOptionsValues) => void;
  getCameraSettings: () => CameraState | undefined;
  addObject: (props: PotreeViewer2AddObjectProps) => void;
  removeObjects: () => void;
  zoomToLocation: (props: PotreeViewer2ZoomToLocationProps) => void;
  addInfoModal: (props: PotreeViewer2InfoModalProps) => void;
  removeInfoModal: () => void;
  bindObjectOnClick: (callback: ((e: CustomEvent) => void) | undefined) => void;
  bindCameraPositionOnClick: (
    callback: ((e: CustomEvent) => void) | undefined
  ) => void;
  updateClassificationClassByLabel: (
    label: number,
    args: {
      visible?: boolean;
      color?: number[];
    }
  ) => void;
  setCameraFrontView: () => void;
  stopObjectSelection: () => void;
  resetAllObjectMarkers: () => void;
  getHighlightedMarkers: () => string[];
  startCropBox: () => void;
  stopCropBox: () => void;
  selectObjectsByLocation2D: (points: Vector3[]) => void;
  get3DPointFromXYZ: (x: number, y: number) => Vector3 | undefined;
  getCropBoxCoordinates: () => string;
  startObjectSelection: () => void;
  drawConvexHull: (points: number[][], coId: string) => void;
  drawTrunkLine: (points: number[][], coId: string) => void;
  addExistingObjectLocation: (
    eventName: EventBusNames,
    eventId: string,
    position: Vector3,
    options: MarkerObjOptions
  ) => void;
  clearAllMarkers: () => void;
  toggleObjectVisibility: (id: string, show: boolean) => void;
  toggleTextLabelById: (id: string, show: boolean) => void;
  clearAllTrunkLines: () => void;
  addAxesHelper: (origin?: Vector3) => void;
  removeAxesHelper: () => void;
  toggleAll3dAnnotations: (show: boolean) => void;
  setCaptureMetadata: (capture?: APIModels.Capture) => void;
  toggleCropEditingTool: () => void;
  finishCropEditingTool: () => void;
  startCropBoxEditingTool: () => void;
  destroyCroppingTool: () => void;
  resetCroppingTool: () => void;

  annotation3d?: IAnnotations3d;
  threeViewer: IViewer | undefined;
  mousePosition: React.MutableRefObject<Vector3 | undefined>;
}

export interface IGs3dViewerController {
  info: {
    error: Error | undefined;
    viewerReady: boolean;
    captureJobMetadata?: APIModels.CaptureJob;
    captureMetadata?: APIModels.Capture;
    cameraPositionsVisible?: boolean;
    cameraPositionsLoaded?: boolean;
    sceneLoaded: boolean;
    element: HTMLElement | null;
    cropBoxEditingActive: boolean;
  };
  loadPlyModel: (url: string) => void;
  // removePlyModel: () => void;
  setBackground: (background: BackgroundOptionsValues) => void;
  setCameraSettings: () => void;
  getCameraSettings: () => CameraState | undefined;
  setCaptureJobMetadata: (captureJob?: APIModels.CaptureJob) => void;
  loadCameraPositions: (images: APIClient.CaptureImage[]) => void;
  setCaptureMetadata: (capture?: APIModels.Capture) => void;
  bindCameraPositionOnClick: (
    callback: ((e: CustomEvent) => void) | undefined
  ) => void;
  setCameraPositionsVisible: (visible: boolean) => void;
  removeCameraPositions: () => void;
  hideCameraPositions: () => void;
  showCameraPositions: () => void;
  addAxesHelper: () => void;
  removeAxesHelper: () => void;
  toggleAll3dAnnotations: (show: boolean) => void;

  annotation3d?: IAnnotations3d;
  threeViewer: IGsThreeDViewer | undefined;
  mousePosition: React.MutableRefObject<Vector3 | undefined>;
  toggleCropEditingTool: () => void;
  finishCropEditingTool: () => void;
  startCropBoxEditingTool: () => void;
  destroyCroppingTool: () => void;
  resetCroppingTool: () => void;
}

export interface IVideoViewerController {
  info: {
    viewerReady: boolean;
  };
  loadVideo: (url: string) => void;
}

export interface IImageViewerController {
  info: {
    viewerReady: boolean;
  };
  loadImage: (url: string) => void;
  clearImage: () => void;
}

export interface IAudioViewerController {
  info: {
    viewerReady: boolean;
  };
  loadAudio: (url: string) => void;
}

export interface IVideoViewer {
  controller?: (controller: IVideoViewerController) => void;
}

export interface IAudioViewer {
  controller?: (controller: IAudioViewerController) => void;
}

export interface IImageViewer {
  controller?: (controller: IImageViewerController) => void;
}

export interface Gs3dViewerProps {
  cloudApp?: boolean;
  controller?: (controller: IGs3dViewerController) => void;
  showTools?: boolean;
  showCloudTools?: boolean;
  showShareTools?: boolean;
  showLoadingIndicator?: boolean;
  plugins?: React.ReactElement[];
}

export interface IGsCloudToolState {
  showSplats?: boolean | undefined;
  showCameraPositions?: boolean | undefined;
  mousePosition?: Vector2 | undefined;
}
